import React, { useEffect, useState } from "react";




const IMAGE_URL='http://content.helloviewer.io/assets/ashirvad/2d/v2/'
const COLOR_CODE: any = {
  "#e2e5e9": 'White', //white
  "#0083d9": 'Blue', //blue
  "#292a2f": 'Black', //black
  "#518f56": 'Green', //green
  "#f1c61a":  'Yellow', //yellow
};
export default function _2DViewer({
  selectedImage,
  setSelectedImage,
  options,
  selectedColor,
  selectedVolume,
  selectedLayers
}: {
  selectedImage: number;
  setSelectedImage: any;
  options: string[];
  selectedColor: string;
  selectedVolume:string;
  selectedLayers:string
  
}) {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setShowLoader(true);
    return () => {};
  }, [selectedColor,selectedVolume,selectedLayers]);
  const getImageSet=()=>{
    let layers=''

    if(selectedLayers==='2'){
      layers='Classic'
    }
    else if(selectedLayers==='3'){
      layers='Pure'
    }
    else if(selectedLayers==='4'){
      layers='WeatherGuard'
    }
    return [
      IMAGE_URL+selectedVolume+'/'+layers+'/'+COLOR_CODE[selectedColor]+'/Front.jpg',
      IMAGE_URL+selectedVolume+'/'+layers+'/'+COLOR_CODE[selectedColor]+'/Top.jpg',
      IMAGE_URL+selectedVolume+'/'+layers+'/'+COLOR_CODE[selectedColor]+'/Bottom.jpg',
  ]
  }
  return (
    <div className="_2DViewer">
      <div className="ThumbnailContainer">
        
        {getImageSet().map((imageUrl: any, idx: number) => (
          <div
            className={
              selectedImage === idx
                ? "Thumbnail selectedThumbnail"
                : "Thumbnail"
            }
            onClick={() => setSelectedImage(idx)}
          >
            <img src={imageUrl} alt="water_tank" />
            
          </div>
        ))}
      </div>
      <div className="selectedImage">
        {selectedColor && (
          <img
            loading="lazy"
            src={getImageSet()[selectedImage]}
            alt="water_tank"
            onLoad={() => setShowLoader(false)}
          />
        )}
        {showLoader && (
          <div className="LoaderContainer">
            <p>Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
}
