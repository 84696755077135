import React from "react";

export default function Tabbar({
  selectedVolume,
  setSelectedVolume,
  options,
}: {
  selectedVolume: number;
  setSelectedVolume: any;
  options: string[];
}) {
  return (
    <div className="VolumeSelectionTab">
      <p className="optionsHeader">Choose Volume (Litres)</p>
      <ul>
        {options.map((item: String, idx: number) => (
          <li
            key={idx}
            className={selectedVolume === idx ? "SelectedTab" : ""}
            onClick={() => setSelectedVolume(idx)}
          >
            <strong>{item}</strong>
          </li>
        ))}
      </ul>
    </div>
  );
}
