import React from "react";
import { ReactComponent as CheckIcon } from "../assets/icons/done_24px.svg";

export default function ColorSelectionBar({
  selectedColor,
  setSelectedColor,
  options,
  selectedLayers
}: {
  selectedColor: number;
  setSelectedColor: any;
  options: string[];
  selectedLayers:number
}) {
  return (
    <div className="ColorSelectionBar">
      <p className="optionsHeader">Choose Colors</p>

      <ul>
        {options.map((item: String, idx: number) => (
          <div
            key={idx}
            className={
              selectedLayers === 0&&options[idx]!=='#292a2f'
                ? "DisabledColorPalette ColorPalette"
                : "ColorPalette"
            }
            
            onClick={() => setSelectedColor(idx)}
            style={{ backgroundColor: options[idx] }}
          >
            {selectedColor === idx && (
              <CheckIcon
                fill="#ffff"
                width="1.5rem"
                height="2rem"
                strokeWidth={6}
              />
            )}
          </div>
        ))}
      </ul>
    </div>
  );
}
