import { useEffect, useState } from "react";


const IMAGE_URL='http://content.helloviewer.io/assets/ashirvad/sliced/v1/'
const COLOR_CODE: any = {
  "#e2e5e9": 'White', //white
  "#0083d9": 'Blue', //blue
  "#292a2f": 'Black', //black
  "#518f56": 'Green', //green
  "#f1c61a":  'Yellow', //yellow
};

export default function SclicedView({
  selectedColor,
  selectedVolume,
  selectedLayers
}: {
  selectedColor: string;
  selectedVolume:string;
  selectedLayers:string
}) {
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    setShowLoader(true);
    return () => {};
  }, [selectedLayers,selectedColor,selectedVolume]);

  const getImage=()=>{
    let layers=''

    if(selectedLayers==='2'){
      layers='Classic'
    }
    else if(selectedLayers==='3'){
      layers='Pure'
    }
    else if(selectedLayers==='4'){
      layers='WeatherGuard'
    }
    return IMAGE_URL+selectedVolume+'/'+layers+'/'+COLOR_CODE[selectedColor]+'/Sliced.jpg'
  
  }

  return (
    <div className="SlicedView">
      
      <div className="selectedImage">
        <img
          loading="lazy"
          src={getImage()}
          alt="water_tank"
          onLoad={() => setShowLoader(false)}
          onLoadStart={() => setShowLoader(true)}
        />
      </div>
      {showLoader && (
        <div className="LoaderContainer">
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
}
