import React from "react";
import { ReactComponent as Layers2 } from "../assets/icons/2 Layer.svg";
import { ReactComponent as Layers3 } from "../assets/icons/3 Layer.svg";
import { ReactComponent as Layers4 } from "../assets/icons/4 Layer.svg";

const LAYER_ICONS: any = {
  "2": <Layers2 width="100%" height="100%" />,
  "3": <Layers3 width="100%" height="100%" />,
  "4": <Layers4 width="100%" height="100%" />,
};
export default function LayerSelectionBar({
  selectedLayers,
  setSelectedLayers,
  options,
}: {
  selectedLayers: number;
  setSelectedLayers: any;
  options: number[];
}) {
  return (
    <div className="LayerSelectionBar">
      <p className="optionsHeader">Choose Layers</p>
      <ul>
        {options.map((item: number, idx: number) => (
          <div>
            <li
              key={idx}
              className={selectedLayers === idx ? "SelectedTab" : ""}
              onClick={() => setSelectedLayers(idx)}
            >
              {LAYER_ICONS[item.toString()]}
            </li>
            {item}
          </div>
        ))}
      </ul>
    </div>
  );
}
