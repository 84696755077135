import { useEffect, useState } from "react";
import "./App.css";
import VolumeSelectionTab from "./components/volumeSelectionTab";
import LayerSelection from "./components/layerSelection";
import ColorSelection from "./components/colorSelection";
import ThreeDViewer from "./components/3DViewer.jsx";
import SclicedView from "./components/SclicedViewer";
import TwoDViewer from "./components/2DViewer";
import Tabbar from "./components/tabbar";

const PROD_CONF: any = {'500': {
  '#292a2f': {
    '2': '607fca32de9919d9705af3ab',
    '3': '607fcb83de9919d9705af3b5',
    '4': '607fcbc9de9919d9705af3bf'
  },
  '#e2e5e9': {
    '3': '607fcaa4de9919d9705af3ad',
    '4': '607fcb95de9919d9705af3b7'
  },
  '#f1c61a': {
    '3': '607fcab5de9919d9705af3af',
    '4': '607fcba2de9919d9705af3b9'
  },
  '#0083d9': {
    '3': '607fcac6de9919d9705af3b1',
    '4': '607fcbaede9919d9705af3bb'
  },
  '#518f56': {
    '3': '607fcad7de9919d9705af3b3',
    '4': '607fcbbcde9919d9705af3bd'
  }
},
'700': {
  '#292a2f': {
    '2': '608000bf42e52c83326452b5',
    '3': '608000f142e52c83326452bf',
    '4': '6080013d42e52c83326452c9'
  },
  '#e2e5e9': {
    '3': '608000c742e52c83326452b7',
    '4': '608000fb42e52c83326452c1'
  },
  '#f1c61a': {
    '3': '608000cf42e52c83326452b9',
    '4': '6080011f42e52c83326452c3'
  },
  '#0083d9': {
    '3': '608000de42e52c83326452bb',
    '4': '6080012c42e52c83326452c5'
  },
  '#518f56': {
    '3': '608000e742e52c83326452bd',
    '4': '6080013642e52c83326452c7'
  }
},
'1000': {
  '#292a2f': {
    '2': '607fdfce01ae6042a0155539',
    '3': '607fe00c01ae6042a0155543',
    '4': '607fe03f01ae6042a015554d'
  },
  '#e2e5e9': {
    '3': '607fdfef01ae6042a015553b',
    '4': '607fe01301ae6042a0155545'
  },
  '#f1c61a': {
    '3': '607fdff601ae6042a015553d',
    '4': '607fe01c01ae6042a0155547'
  },
  '#0083d9': {
    '3': '607fdffd01ae6042a015553f',
    '4': '607fe02901ae6042a0155549'
  },
  '#518f56': {
    '3': '607fe00401ae6042a0155541',
    '4': '607fe03101ae6042a015554b'
  }
},
'1500': {
  '#292a2f': {
    '2': '607fe7f3c9b83b6b997ffb61',
    '3': '607fe820c9b83b6b997ffb6b',
    '4': '607fe84fc9b83b6b997ffb75'
  },
  '#e2e5e9': {
    '3': '607fe807c9b83b6b997ffb63',
    '4': '607fe829c9b83b6b997ffb6d'
  },
  '#f1c61a': {
    '3': '607fe80ec9b83b6b997ffb65',
    '4': '607fe830c9b83b6b997ffb6f'
  },
  '#0083d9': {
    '3': '607fe814c9b83b6b997ffb67',
    '4': '607fe83bc9b83b6b997ffb71'
  },
  '#518f56': {
    '3': '607fe81ac9b83b6b997ffb69',
    '4': '607fe841c9b83b6b997ffb73'
  }
},
'2000': {
  '#292a2f': {
    '2': '607fed5b99ac0e326afddddb',
    '3': '607fed8799ac0e326afddde5',
    '4': '607fedbb99ac0e326afdddef'
  },
  '#e2e5e9': {
    '3': '607fed6999ac0e326afddddd',
    '4': '607fed9399ac0e326afddde7'
  },
  '#f1c61a': {
    '3': '607fed7099ac0e326afddddf',
    '4': '607fed9a99ac0e326afddde9'
  },
  '#0083d9': {
    '3': '607fed7999ac0e326afddde1',
    '4': '607feda199ac0e326afdddeb'
  },
  '#518f56': {
    '3': '607fed8199ac0e326afddde3',
    '4': '607fedac99ac0e326afddded'
  }
}}

const ALL_LAYER_SET = {
  "2": ["Outer Layer: UV Stable - Makes the tank long lasting",
   "Inner Layer: Anti-bacterial, Anti-viral, Anti-algae, Anti-fungal-Ensures safety and hygiene"],

  "3": ["Outer Layer: UV Stable - Makes the tank long lasting",
   "Middle Layer: Imparts additional strength and restricts entry of harmful UV Rays",
    "Inner Layer: Anti-bacterial, Anti-viral, Anti-algae, Anti-fungal-Ensures safety and hygiene"],
  
  "4": ["Outer Layer: UV Stable - Makes the tank long lasting",
   "Second Layer: Imparts additional strength and restricts entry of harmful UV Rays",
    "Foam Layer: Provides thermal insulation to the stored water and also imparts additional strength",
     "Inner Layer: Anti-bacterial, Anti-viral, Anti-algae, Anti-fungal-Ensures safety and hygiene"],
};


;

const VOLUMES = Object.keys(PROD_CONF).map((key) => key);

function App() {
  const [selectedTab, setSelectedTab] = useState<"2D" | "360" | "Sliced">("2D");

  const [selectedVolume, setSelectedVolume] = useState<number>(0);
  const [selectedLayers, setSelectedLayers] = useState<number>(1);
  const [selectedColor, setSelectedColor] = useState<any>(2);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
    return () => {};
  }, [selectedColor, selectedVolume]);

  useEffect(() => {
    
    if(selectedLayers===0){
      setSelectedColor(0)
    }
  }, [selectedLayers,selectedColor])

  const getProductID = () => {
    const colors = PROD_CONF[VOLUMES[selectedVolume]];
    if (getColorList.length - 1 > selectedColor) {
      setSelectedColor(0);
      return colors[0];
    }
    if(selectedLayers===0){
      return colors[getColorList()[0]][Object.keys(ALL_LAYER_SET)[selectedLayers]];
    }
    return colors[getColorList()[selectedColor]][Object.keys(ALL_LAYER_SET)[selectedLayers]];
  };

  const getColorList = () => {
    if(selectedLayers===0){
      return ['#292a2f']
    }
    let arr= (Object.keys(PROD_CONF[VOLUMES[selectedVolume]]) as any).map(
      (key: any) => key
    ).sort();
    return arr.slice(1, arr.length).concat(arr.slice(0, 1));
  };

  ;
  

  const getView = () => {
    switch (selectedTab) {
      case "2D":
        return (
          <TwoDViewer
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            options={[]}
            selectedColor={getColorList()[selectedColor]}
            selectedLayers={Object.keys(ALL_LAYER_SET)[selectedLayers]}
            selectedVolume={VOLUMES[selectedVolume]}
          />
        );

      case "360":
        return (
          <ThreeDViewer
            product_id={getProductID()}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
          />
        );

      case "Sliced":
        return (
          <SclicedView
          selectedColor={getColorList()[selectedColor]}
            selectedLayers={Object.keys(ALL_LAYER_SET)[selectedLayers]}
            selectedVolume={VOLUMES[selectedVolume]} />
        );

      
    }
  };
  return (
    <div className="App">
      <div className="flex-row">
        <div className="ProductView">
          <div className="viewerContainer">{getView()}</div>
          <Tabbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
        <div className="RightSection">
          <VolumeSelectionTab
            selectedVolume={selectedVolume}
            setSelectedVolume={setSelectedVolume}
            options={VOLUMES}
          />
          <div className="Divider"></div>
          <LayerSelection
            selectedLayers={selectedLayers}
            setSelectedLayers={setSelectedLayers}
            options={[2,3,4]}
          />
          <div className="Divider"></div>
          <div className="LayersInfo">
            <p className="optionsHeader">Layer distribution</p>
            <ul>
              {
                Object.values(ALL_LAYER_SET)[selectedLayers].map(
                  (item: string, idx: number) => (
                    <div className='LayerDescription'>
                    <li key={idx}>
                      {idx + 1}. {item.split(':')[0]+' :'}
                    </li>
                    <p>{' '+item.split(':')[1]}</p>
                    </div>
                  )
                )}
            </ul>
          </div>
          <div className="Divider"></div>
          <ColorSelection
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            options={getColorList()}
            selectedLayers={selectedLayers}
          />

          {/* <GithubPicker triangle="hide" styles={{}} /> */}
          {/* <MaterialSelection
            selectedMaterial={selectedMaterial}
            setSelectedMaterial={setSelectedMaterial}
          /> */}
        </div>
      </div>
    </div>
  );
}
export default App;
