export default function Tabbar({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: "2D" | "360" | "Sliced";
  setSelectedTab: any;
}) {
  return (
    <div className="TabBar">
      <ul>
        <li
          className={selectedTab === "2D" ? "SelectedTab" : ""}
          onClick={() => setSelectedTab("2D")}
        >
          2D View
        </li>
        <li
          className={selectedTab === "360" ? "SelectedTab" : ""}
          onClick={() => setSelectedTab("360")}
        >
          360° view
        </li>
        <li
          className={selectedTab === "Sliced" ? "SelectedTab" : ""}
          onClick={() => setSelectedTab("Sliced")}
        >
          Sliced view
        </li>
      </ul>
    </div>
  );
}
